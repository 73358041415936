<template>
    <div class="terms full-width" v-if="translates && translates[langUrl]">
        <div
            class="terms__main-image">
            <div class="terms__main-image__opacity"></div>
            <div class="terms__main-image__container">
                <!-- <div v-if="!isDesktop" class="main-page__main-image__container__filter">
                    <v-text-field
                        label="Направление"
                    />
                </div> -->
                <div class="terms__main-image__container__text">Booking Terms</div>
                <!-- <div class="terms__main-image__container__comment">6 простых шагов!</div> -->
                <!-- <div v-if="pageTemplate.main.links" class="main-page__main-image__container__directions">
                    <template v-for="(link, index) in pageTemplate.main.links">
                        <div
                            :key="`main-links-${index}`"
                            class="terms__main-image__container__direction"
                            @click="$router.push(link.link)">{{ link.title }}</div>
                    </template>
                </div> -->
            </div>
        </div>
        <div class="terms__text__container">
            <div class="terms__text">
                <div class="terms__text__updated">Last updated Jan 09, 2023</div>
                <h2>Traveler Booking Terms</h2>

                <h3>GetAdventures.co</h3>
                <h3>Booking Agreement</h3> 
                <h3>Terms and Conditions</h3>

                <p>These Terms and Conditions (these “<span>Terms</span>”) govern each user’s (“<span>you</span>”, “<span>your</span>”, or “<span>user</span>”) access to and use of the site at <span>getadventures.co</span> (the “<span>Website</span>” or “<span>Company Website</span>”) which is owned and/or operated by Go-Peaks, Inc. a California corporation (“<span>Company</span>”, “<span>GetAdventures</span>”, “<span>we</span>”, “<span>us</span>”, “<span>our</span>”), and any other products, mobile applications, other apps or other platforms the Company offers (hereinafter, the <span>Website</span> and these other products, <span>mobile applications</span>, other <span>apps</span> and/or other platforms are collectively referred to as “<span>Company Programs</span>”).</p>
                <p>The term "you" “traveler” refers to the traveler making a booking with us and all travelers for whom the traveler makes reservations.</p>
                <p>CONTRACT. We draw your attention to the Terms and Conditions of travel below, these Terms and Conditions including all brochures, documents, correspondence and the terms and conditions of our suppliers form the basis of the contract with you. Before making a booking with us you must ensure that you have read and understood these Terms and Conditions (and asked us any questions that you may have). Please be aware that these terms and conditions contain waivers of liability as well as waiver of class action and venue selection and notice clauses. BECAUSE THESE TERMS AND CONDITIONS AFFECT YOUR LEGAL RIGHTS, YOU SHOULD READ THEM CAREFULLY. By asking us to confirm your booking you are accepting all of the Terms and Conditions laid out below and acknowledging that you have read the terms of this contract and agree with it.</p>
                <p>If you are making a booking as a group, the leader of the group is responsible for sharing these Terms and Conditions with all members of the group and is financially responsible for the booking. We will not be liable for a group leader’s failure to share these Terms and Conditions with all travelers in their group.</p>
                <p>You represent and warrant that (a) you are of sufficient age to use our services and website and can create binding legal obligations in connection with your use, (b) you are legally authorized to act on behalf of those you represent and accept these Terms and Conditions on their behalf, and (c) the information supplied by you or members of your group is true and correct.</p>
                <p>VIOLATIONS BY YOU. You agree that any violation of any of the Terms and Conditions may result in (a) the cancellation of your reservation or purchase, (b) your forfeiture of any monies paid for your reservation or purchase, and (c) your being denied access to the applicable travel related product or service.</p>
                <p>BY CONTINUING TO USE OR OTHERWISE ACCESS THE SITE OR ANY OTHER COMPANY PROGRAM (INCLUDING WITHOUT LIMITATION STARTING TO BOOK ANY TRIP), YOU HEREBY AUTOMATICALLY AGREE TO BE BOUND BY ALL OF THE PROVISIONS OF THIS AGREEMENT (AS DEFINED ABOVE), AS PRESENTED TO YOU AS OF THE DATE OF YOUR FIRST USE OF EITHER THE SITE OR ANY OTHER COMPANY PROGRAM.</p>
                <p>CHANGES TO THESE TERMS AND CONDITIONS. Company reserves the right, in our sole discretion, to change these Terms and Conditions at any time. Updated versions of the Terms and Conditions will be posted here on this website and are effective immediately on posting. If we make material changes, we will notify you. Please check frequently, especially before you make a booking, to see if these Terms and Conditions changed. Your continued use of our services including continuing to use or maintain any bookings after any changes to the Terms and Conditions constitutes your consent to the changes.</p>
                <p>NO CHANGES (ADDITIONS OR DELETIONS) BY YOU TO THIS AGREEMENT WILL BE ACCEPTED BY THE COMPANY. THUS, IF YOU DO NOT AGREE TO ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT, THEN YOU SHOULD NOT ACCESS OR OTHERWISE USE THE SITE OR ANY OTHER COMPANY PROGRAMS.</p>
                <p>THIS AGREEMENT CONSTITUTES A BINDING CONTRACT BETWEEN YOU AND THE COMPANY AND YOU SHOULD DOWNLOAD AND PRINT THIS ENTIRE AGREEMENT (INCLUDING WITHOUT LIMITATION THESE TERMS AND THE COMPANY’S PRIVACY POLICY, AS DEFINED ABOVE) FOR YOUR RECORDS.</p>
                <p>COVID 19 RELEASE OF LIABILITY. By booking a tour at this time, you acknowledge the highly contagious nature of COVID-19 and voluntarily assume the risk for yourself and any minors traveling with you, that you or they may be exposed to or infected by COVID-19 by traveling and that such exposure or infection may result in personal injury, illness, permanent disability, and death even if such injuries or losses occur in a manner that is not foreseeable at the time you book your tour. You acknowledge that exposure to such viruses or disease is an inherent risk of traveling, that cannot be controlled or eliminated by Go-Peaks.</p>
                <p>You acknowledge that due to the uncertainty of travel at this time, your tour may be postponed or canceled, or changes may be made to itineraries due to closures of certain sites or activities, for which there may be no refund. All destinations and suppliers have their own rules related to COVID-19. For example, you may be required to quarantine upon arrival in some locations. Some locations may require masks or social distancing, they may require you to provide proof of vaccination or negative testing. Stopover countries requirements will also apply. On your return home, additional testing, requirements, or documentation may be required. While we will try to assist you in understanding these requirements, you are responsible for understanding these requirements and must not rely on any representations made by Go-Peaks. Should you be denied entry to any destination, we shall not be responsible for any such denial, or any cost associated therewith. You understand that you may become sick before, during, or after the tour and may not be able to travel and such cancellation or interruption will be subject to our cancellation terms below, for which we will not be liable.</p>
                <p>You understand that Company does not sell travel protection, and that it is your responsibility to protect your purchase. You agree that due to uncertainty caused by COVID-19, Company has strongly encouraged the purchase of travel protection coverage including cancel for any reason coverage if and when available, and that should you fail to purchase travel protection coverage, Company shall not be liable to any losses howsoever arising. Company shall not be liable if travel protection does not cover COVID-19 related losses.</p>
                <p>You, for yourself, and any minors traveling with you, and on behalf of your and their heirs, assigns, personal representatives and next of kin (The Releasors), HEREBY RELEASE, AND HOLD HARMLESS Go-Peaks Inc., its shareholders, contractors, directors, officers, agents, and/or employees, suppliers, and other tour members (RELEASEES), of from and against any and all claims, damages, demands, losses, and liability arising out of or related in any way, in whole or in part to any POSTPONEMENT, CANCELLATION, CHANGES, INJURY, DISABILITY, DEATH OR ANY OTHER LOSS you may suffer due to exposure, infection, spread, closure, and travel restrictions related to COVID- 19, WHETHER ARISING FROM THE NEGLIGENCE OF THE RELEASEES OR OTHERWISE, to the fullest extent permitted by law. The terms of this HOLD HARMLESS AND RELEASE OF ALL LIABILITY paragraphs, shall survive any termination or cancellation of this Contract, whether by operation of law or otherwise.</p>
                <p>PRIVACY NOTICE</p>
                <p>ALL USERS (INCLUDING WITHOUT LIMITATION ALL TRAVELERS AS DEFINED HEREIN) ARE DIRECTED TO SECTIONS 4 OF THIS AGREEMENT FOR A LINK TO THE COMPANY’S PRIVACY POLICY. WITHOUT LIMITING THE PROVISIONS OF SECTIONS 4 and 5.21 HEREIN OR ANY OF THE PROVISIONS OF THE COMPANY’S PRIVACY POLICY, A USER’S USE OF ANY COMPANY PROGRAM, INCLUDING WITHOUT LIMITATION BOOKING ANY TRIP, CONSTITUTES YOUR AGREEMENT TO OUR COLLECTION AND PROCESSING OF YOUR PERSONAL INFORMATION, INCLUDING THE USE OF COOKIES, PURSUANT TO THE COMPANY’S PRIVACY POLICY. PLEASE READ THE COMPANY’S PRIVACY POLICY (SEE SECTIONS 4 and 5.21 HEREIN) CAREFULLY AS IT CONTAINS IMPORTANT INFORMATION ABOUT OUR COLLECTION, USE, STORAGE, DISCLOSURE, AND TRANSFER OF YOUR INFORMATION. IF YOU DO NOT AGREE TO ALL OF THE TERMS OF THE COMPANY’S PRIVACY POLICY, THEN YOU MAY NOT USE THE SITE OR ANY OTHER COMPANY PROGRAM.</p>
                <p><span>1. Definitions.</span> The following terms have the following meanings:</p>
                <p><span>1.1 “Access Right”</span> has the meaning set forth in Section 2 herein.</p>
                <p><span>1.2 “Booking(s)”</span> or <span>“Book a Trip”</span> has the meaning set forth in Section 5 herein.</p>
                <p><span>1.3 “Content”</span> means any data, information, messages, text, photos, graphics, videos, messages, tags, works, material or any other content, including, without limitation, any personal identifiable information.</p>
                <p><span>1.4 “Company IP Assets”</span>has the meaning set forth in Section 3.2 herein.</p>
                <p><span>1.5 “Company Privacy Policy”</span> has the meaning set forth in Section 4 herein.</p>
                <p><span>1.6 “Company Server”</span> or <span>“Server”</span> means the computer software or hardware that serves and hosts the Website or Company Programs to users across the Internet.</p>
                <p><span>1.7 “Host”</span> means the influencer, entrepreneur, or other business that hosts a Trip which Travelers can then Book by using the Company Website or other authorized Company Program.</p>
                <p><span>1.8 “Organizer”</span>has the meaning defined in Section 5 herein.</p>
                <p><span>1.9 “Trip Leader”</span> The local representative assigned by the “Operator” who is the authority on the trip organizing the logistics and overseeing the group.</p>
                <p><span>1.10 “Traveler”</span> means any individual user of the Company Website or other authorized Company Program who Books a Trip using such a Company Website or other Company Program.</p>
                <p><span>1.11 “Trip”</span> means the itinerary with identified host, dates, and price as more specifically described in the Company Website.</p>
                <p><span>1.12 “Trip Payments”</span> means the Trip payments that will be processed by Company as follows:</p>
                <p>1.12.1 The Trip payment that the Company will process on behalf of the Traveler who Books a Trip through the Trip Services, such payments to be processed and made by the Company on behalf of the Traveler to the third parties as described in, and otherwise pursuant to, the terms and conditions of the Bookings.</p>
                <p>1.12.2 The Trip fees paid by a Host while using the Trip Services which will be transmitted through the Trip Services from the Host to a third party as agreed to by Company pursuant to said Trip Services.</p>
                <p>1.12.3 The Trip fees paid to an Operator while using the Trip Services which will be transmitted through the Trip Services from the Organizer to a third party as agreed to by Company pursuant to said Trip Services.</p>
                <p><span>1.13 “Trip Services”</span>means the following:</p>
                <p>1.13.1 For Travelers, it has the meaning set forth in Section 5 herein;</p>
                <p>1.13.2 For Hosts, it means the service offered to Hosts through the Website or other Company Program to assist the Host in coordinating certain logistics of a Trip for Travelers and Organizers; and/or</p>
                <p>1.13.3 For Operators, it means the service offered to Operators through the Website or other Company Program to assist the Operator in coordinating certain logistics of a Trip for Travelers and Hosts.</p>
                <p><span>1.14 “Trip Credit”</span>: voucher payment which must be applied towards a new trip within 365 days of issue.</p>
                <p><span>1.15 “Reschedule”</span> means a trip that has been postponed due to a Force Majeure event. The newly scheduled dates will be within 14 months of the previously planned departure.</p>
                <p><span>1.16 “Cancellation”</span> means any significant date alternation not caused by a Force Majeure event or the complete removal of a trip from sale and future operation by any party.</p>
                <p>2. ACCESS AND CONDITIONS OF USE OF COMPANY WEBSITE AND ANY OTHER COMPANY PROGRAMS</p>
                <p>2.1 Subject to all of the terms and conditions of this Agreement, Company hereby grants to a user a non-exclusive, revocable, and limited right to access and use this Company Trip Website or Company Program, including without limitation any of the Trip Services provided through the Company Website or Company Program, in strict compliance with this Agreement (“Access Right”). Company reserves the right to suspend or revoke this Access Right at Company’s discretion without notice. Notwithstanding the foregoing Access Right, Company also has the right to change, suspend, or discontinue any (or all) aspects or features of this Company Website or Company Program or any of the Trip Services at any time, and from time to time, including the availability of any content or features on this Company Website or Company Program or any of the Trip Services. This Access Right granted to user under this Agreement will immediately terminate upon the expiration, cancellation or termination of this Agreement for any reason.</p>
                <p>2.2 User shall be responsible for obtaining and maintaining any equipment or ancillary services needed to connect to or access this Company Website or Company Program, including, without limitation, Internet connections, modems, hardware, software, and long distance or local telephone service.</p>
                <p>2.3 As a condition of each user’s use of this Company Website or Company Program, including without limitation any Trip Services, the user covenants to Company that: (a) user shall not use the Company Website or Company Program (including without limitation any Trip Services): (i) for any unlawful purpose or for any purpose that is prohibited by this Agreement; or (ii) in any manner that could damage, disable, overburden, or impair the Company Website or Company Program (including without limitation any Trip Services), or that would interfere with any other party's use and enjoyment of the Company Website or Company Program (including without limitation any Trip Services); and (b) user shall not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided through the Company Website or Company Program (including without limitation through any Trip Services).</p>
                <p>2.4 Notwithstanding any assistance that Company may provide, the user assumes sole responsibility for the uploading and updating of any of the user’s Content that is contained in the Company Website or Company Program.</p>
                <p>2.5. Users further agree as follows:</p>
                <p>2.5.1 Without limiting the generality of the foregoing, user agrees to all of the following provisions:</p>
                <p>(a) User will not upload to, distribute or otherwise publish through this Company Website or Company Program any Content that is unlawful, libelous, defamatory, invasive of privacy or publicity rights, harassing, threatening, abusive, inflammatory, obscene, or otherwise objectionable;</p>
                <p>(b) User will not upload or transmit any Content that would violate the rights of any party, would constitute or encourage a criminal offense, or would otherwise create liability or violate any local, state, federal or international law,</p>
                <p>(c) User will not upload or transmit any Content that may infringe any patent, trademark, trade secret, copyright, or other intellectual or proprietary right of any party anywhere;</p>
                <p>(d) User will not impersonate any person or entity or otherwise misrepresent the user’s affiliation with a person or entity;</p>
                <p>(e) User will not distribute or publish unsolicited promotions, advertising, or solicitations for any goods, services or money, including junk mail and junk e-mail;</p>
                <p>(f) User will not use the Company Website or Company Program for purposes not authorized by Company; and</p>
                <p>(g) User will not use the Company Website or Company Program for any illegal purpose or any fraudulent scheme or transaction.</p>
                <p>2.5.2 In addition to the rights given to Company pursuant to Section 8 of this Agreement, user hereby grants Company a perpetual, worldwide, transferable, fully paid up right to use any of the user’s Content to:</p>
                <p>(a) provide the user with the Trip Services contemplated by this Company Website or Company Program, under this Agreement or under any other contract between user and Company, including without limitation any other uses normally intended for users;</p>
                <p>(b) to assist or coordinate with any claims arising out of the use of the Company Website or Company Program, including without limitation any claims involving any Host or Operators; and</p>
                <p>(c) for any other lawful purpose in carrying out Company business, operation or corporate purpose.</p>
                <p>2.6 Without limiting the generality of any other provisions herein, User agrees to all of the following provisions:</p>
                <p>2.6.1 Users are prohibited from violating or attempting to violate the security of this Company Website or Company Program or any Company Server (as defined herein), including, without limitation, (i) accessing data not intended for such user or logging into a server or account which the user is not authorized to access; (ii) attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization; (iii) attempting to interfere with service to any user, host, or network; or (iv) sending unsolicited e-mail, including promotions and/or advertising of products or services.</p>
                <p>2.6.2 Any violations of any system or network security (including, but not limited to, that of this Company Website or Company Program or any Company Server may result in civil or criminal liability; and</p>
                <p>2.6.3 Company has the right to investigate occurrences that may involve such violations and may involve, and cooperate with, law enforcement authorities in prosecuting Users who are involved in such violations. Company also reserves the right to cooperate with any and all law enforcement agencies, including complying with warrants, court orders and subpoenas and disclosing to law enforcement agencies any information about any User and anything a user does with respect to this Company Website or Company Program. By user’s use of the Company Website or Company Program, user hereby authorizes Company to take such action.</p>
                <p>2.7 If the user uploads, transmits, or otherwise contributes any Content (either owned by the User or otherwise) to the Company Website or Company Program, user hereby represents and warrants to Company that the user has the lawful right to distribute and reproduce such Content. Also, user is solely responsible for its conduct (and the conduct of its users) while using the Company Website or Company Program, including, but not limited to, all Content in any folders or web pages (if any), or through any other transactions or interactions user generates, transmits, or maintains via the Company Website or Company Program. Company takes no responsibility for any such online distribution or publication by user or by any other party. Company cannot and will not review every message or other Content that User or any other party may generate or post, and Company is not responsible for the Content thereof.</p>
                <p>2.8 In addition to any other right to terminate this Agreement, Company hereby has the absolute right to immediately terminate, without warning, any account that it believes, in its sole discretion, breaches any of the provisions of this Section.</p>
                <p>3. COPYRIGHTS, TRADEMARKS, AND OTHER INTELLECTUAL PROPERTY RIGHTS; RESERVATION OF RIGHTS.</p>
                <p>3.1 It is the Company’s policy is to respect the copyright, trademarks, and intellectual property rights of others. Company has the absolute right to (i) immediately terminate, without warning, all rights (including, without limitation, all Access Rights and any rights of any user who (in the Company’s determination) appear to infringe upon the copyright, trademarks, or intellectual property rights of others, and/or (ii) remove any such Content from a user from the Company Website or Company Program or used in any Trip Service that, in Company’s determination, may infringe the copyright, trademarks, or other intellectual property rights of any third party.</p>
                <p>3.2 Each user agrees to the following:</p>
                <p>3.2.1 Company or its licensors own all rights, title and interest, in the U.S. and elsewhere, in and to all trademarks, service marks (whether registered or common law marks), logos, and any other trade names displayed on or in the Company Websites and all other Company Programs (including without limitation the Company’s Trip Services) or otherwise embodied in or associated with any and all of the Company’s other products and services (hereinafter collectively referred to as “Trademarks/Trade Dress”). Users shall not use any Trademarks/Trade Dress in connection with any product or service that is not owned by Company, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits the Company. All other trademarks not owned by the Company which may appear in the Company Websites or any other Company Programs are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by Company.</p>
                <p>3.2.2 All content included on or made available through any Company Websites or any other Company Programs, including without limitation any text, graphics, logos, page headers, button icons, scripts, images, audio clips, digital downloads, and data compilations, and all copyrights contained or arising out of the foregoing, is and shall remain the sole property of Company and is protected by United States and international copyright laws (collectively, “Works”).</p>
                <p>3.2.3 Without limiting the provisions of Section 3.2.1 or 3.2.2 above, user hereby acknowledges and agrees that Company or its licensors are the sole owners, worldwide, of all Trademarks/Trade Dress (as defined in Section 3.1.1 above), all Works (as defined in Section 3.1.2 above), and any and all other inventions, patents, logos, images, graphics, photos, videos, icons, content, features, functionalities, data, processes, techniques, software, website designs, copyrights, works, and all other intellectual property provided in, made available by using, or otherwise contained or embodied in, all Company Websites and all other Company Program, or otherwise used by Company in the furtherance of its Trip Services or any of its other products, services or overall business (collectively “Company IP Assets”).</p>
                <p>3.3 Company IP Assets are protected by the copyright, patent, trademark, and/or other intellectual property laws of both the United States and other countries. This Agreement shall not be interpreted to: (i) grant any rights to any user in or to any of the Company IP Assets except for the limited Access Right set forth and subject to the terms and conditions of Section 2.1 of this Agreement; or (ii) transfer any rights in any Company IP Assets or in any other Company intellectual property rights from Company to any users.</p>
                <p>3.4 Company IP Assets may not be used by user without the prior written permission from the Company. Any rights not expressly granted herein to User are reserved to Company. In addition to any other conditions on the user’s Access Right as set forth in this Agreement, the user’s Access Right is subject to the following additional conditions: (i) user shall not modify, disassemble, decompile or reverse translate or create derivative works from any of the Company IP Assets or otherwise attempt to derive any source code of the same or let any third party do the same; (ii) no copyrighted material, content, or any other Company IP Assets may be downloaded, modified, copied, displayed, transferred, distributed, sold, published, broadcast or otherwise used except as expressly stated either in such materials or in this notice without the express prior written permission of the Company (which the Company may or may not grant in its sole discretion); (iii) user shall not remove, alter, cover or obscure any copyright notices or other proprietary rights notices of COMPANY or any other party placed on or embedded in the Company IP Assets and shall otherwise retain all such notices on all copies of the same; and (iv) use of any of the Company IP Assets is prohibited unless user is an authorized User in good standing. Unauthorized use is a violation of copyright and other intellectual property rights and is actionable under law.</p>
                <p>3.5 User agrees to keep strictly confidential all Company IP Assets that have not been made publicly available by Company. User also acknowledges and agrees that the terms and conditions of this provision shall survive the cancellation, expiration or termination of this Agreement for any reason.</p>
                <p>4. PRIVACY.</p>
                <p>4.1 User agrees that: (i) if the user has any User Content or any User login or password that it uses in association with the Company Website or Company Program or is otherwise used in association with any Trip Service, then User is solely responsible for maintaining the confidentiality of the same; and (ii) if the user has any login or password associated with the Company Website or Company Program or that is otherwise used in association with any Trip Service, then the user: (a) is solely responsible for all uses of its login and password regardless of whether these uses are authorized by the user, and (b) the user will immediately notify Company of any unauthorized use of the User’s login and password.</p>
                <p>4.2 Each user’s access to and use of the Company Website or any other Company Programs, including without limitation using any of the Trip Services, is subject to the Company’s Privacy Notice, a copy of which is available here https://getadventures.co/privacy-policy and which is hereby incorporated by reference into this Agreement (“Company Privacy Policy”). IN ADDITION TO ANY OTHER APPLICATIONS OR USES OF PERSONAL INFORMATION AS SET FORTH IN THE COMPANY PRIVACY POLICY, THE COLLECTION, USE, DISCLOSURE AND OTHER PROCESSING OF A USER’S PERSONAL INFORMATION IS GOVERNED BY THE COMPANY PRIVACY POLICY. As a result of such incorporation, each User hereby agrees to comply with the Company Privacy Policy, as it may be amended from time to time by the Company.</p>
                <p>5. TRAVELER’S BOOKING TERMS AND CONDITIONS; SCOPE OF COMPANY’S TRIP SERVICES.</p>
                <p>5.1 Booking; Company’s Trip Services for Travelers.</p>
                <p>5.1.1 All bookings of a Trip by a Traveler must be made with the Company by using the Website or another authorized Company Program that has been made available to the Traveler by the Company. By booking a Trip (“Booking(s)” or “Book a Trip”) with the Company you hereby are deemed to have agreed to this Agreement, including without limitation all of the provisions of this Section 5 governing the Booking, and your Booking will only be accepted by the Company on this basis. Unless otherwise stated on your Trip Page cancellation policy, your payment terms are specifically defined below.</p>
                <p>5.1.2 The Trip services to be provided by the Company when a Traveler Books a Trip when using the Company Website or any other Company Program will be limited to acceptance of the Booking and the Company’s processing of Trip Payments (as defined above) as described in that Booking (the “Trip Services”). FOR CLARITY, AND WITHOUT LIMITING THE FOREGOING IN ANY WAY, THE TRAVELER HEREBY ACKNOWLEDGES AND AGREES THAT THE SOLE AND EXCLUSIVE RESPONSIBILITY AND LIABILITY OF THE COMPANY RELATED TO OR ARISING OUT OF THE BOOKING AND THE TRIP SERVICES SHALL BE LIMITED TO THE COMPANY’S PROCESSING OF THE TRIP PAYMENTS TO THE APPROPRIATE THIRD PARTY PURSUANT TO THE TERMS AND CONDITIONS OF THE PARTICULAR BOOKING.</p>
                <p>5.1.3. All other services to be provided related to the Booking are the sole and exclusive responsibility of the Operator of the Trip (the “Operator”) identified in the Booking (or the Host, as applicable), and are subject to the terms and conditions of any agreements between you and the Operator (or any Host, as applicable). The Operator is solely responsible to you for honoring any confirmed Bookings and making available any Trips reserved through the Services. The Operator is also solely and exclusively responsible to provide all information legally required to be provided to you. If you choose to enter into a transaction with an Operator for the Booking of a Trip, you hereby agree to accept any terms, conditions, rules and restrictions associated with such Booking imposed by the Operator and will receive necessary information from the Operator only. You further acknowledge and agree as follows with regard to all Bookings involving all such Operators:</p>
                <p>(a) The Company is not a party to your agreement with any Operator but only acts as a neutral third party that facilitate you being introduced to that Operator;</p>
                <p>(b) The Company’s sole responsibility under this Agreement, including without limitation with regard to all Bookings and Trip Services, is to transmit the Trip Payments to that Operator; and</p>
                <p>(c) The Company does not have, and does not accept, any responsibility arising from or related to any of your agreements with the Operator or any other third party unless explicitly required by applicable law.</p>
                <p>5.2 Down Payment Requirement. A Traveler is required to pay a 25% down payment (“Down Payment”) per person per Booking to be confirmed. Your Down Payment is refundable until your Booking is confirmed in writing. Except as provided below, your Down Payment is non-refundable after your Booking is confirmed. If your Booking is made within 90 days of the departure date identified in the Booking (the “Departure Date”), then the full amount is payable at the time of Booking and is deemed the Down Payment for purposes of this Agreement.</p>
                <p>5.3 Payments through Affirm: If a Traveler chooses to finance their trip through Affirm, the Terms remain binding and valid in all respects, including without limitation all payment obligations. Your payment agreement with Affirm is separate to Company and you will be subject to their terms and conditions.</p>
                <p>5.4 Acceptance of Booking and Final Payments. If the Company and Host accept your Booking, we will issue a confirmation email. A contract will exist between the Company and you, the Traveler, for only the Trip Services described in Section 5.1 of this Agreement, such agreement to commence as of the date we issue the confirmation email, or if you Book within 7 days the Departure Date, the contract will exist when we accept your payment. Please refer to your Booking confirmation email for details regarding final payments. Payments, other than the Down Payment, made towards the balance of the Booking are referred to as the “Additional Payments”. Payment of the balance of the Booking price is due 90 days before the Departure Date. If this balance is not paid on or before the due date, we reserve the right to cancel your Booking, subject to the cancellation and refund policy provided below. All credits provided in this Agreement must be used within 12 months of the date of cancellation.</p>
                <p>5.5 Prices & Surcharges. Prices are subject to variable and seasonal pricing, both of which are standard practice within the travel industry. This means prices may vary at any time. It is likely that different passengers on the same trip have been charged different prices. Discounts cannot be combined. A maximum of one discount may be applied per traveler per Booking. Any reduced pricing or discounts that may become available after you have paid your Down Payment will not apply to your Booking. If you wish to cancel your Booking to take advantage of a cheaper price, full cancellation conditions apply. Prices are all presented in USD. We reserve the right to impose surcharges up to 60 days before the Departure Date due to unfavorable changes in exchange rates, increases in airfares or other transportation costs, increases in local operator costs, taxes, or if government action should require us to do so. In such instances, we will be responsible for any amount up to 2% of the Booking price and you will be responsible for the balance. If any surcharge results in an increase of more than 10% of the Booking price, you may cancel the booking within 14 days of notification of the surcharge and obtain a full refund. Please note that a surcharge may be applied to all purchases made by credit card.</p>
                <p>5.6 Your Details. In order for us to confirm your travel arrangements for the Booking of your Trip, you must provide all requested details prior to Departure Date. Necessary details vary by Booking, and may include without limitation, full name as per passport, date of birth, nationality, passport number, passport issue and expiry date and any pre-existing medical conditions you have which may affect your ability to complete your travel arrangements. Failure to provide requested details may result in additional charges or non-refundable cancellation of your Booking.</p>
                <p>5.7 Cancellation by the Traveler. If you cancel some or all portions of your Booking, cancellation fees will apply. A cancellation will only be effective when we receive written confirmation of the cancellation. Payments shall be refundable pursuant to the following:</p>
                <p>5.7.1 If you cancel any Booking that is confirmed, on or after the date that is 90 days prior to the Departure Date, the Down Payment and Additional Payments will be retained by the Company and is non-refundable to you.</p>
                <p>5.7.2 For Departure Dates more than 90 days in advance, if your Booking has not yet been confirmed, and you cancel the Booking, you shall either be granted a full refund of your Down Payment, or be granted a credit for the amount of the Down Payment.</p>
                <p>5.7.3 For Departure Dates more than 90 days in advance, if your Booking has been confirmed, and you cancel the Booking, you shall be granted a trip credit in the amount of the Down Payment, less $100 cancellation fee for each spot cancelled. Note that different cancellation conditions including higher charges apply to some styles of booking and additional services, as provided in your confirmation email. If you leave a trip for any reason after it has commenced, we are not obliged to make any refunds for unused Services. If you fail to join a Trip, join it after departure, or leave it prior to its completion, no refund will be made. The above cancellation fees are in addition to fees which may be levied by accommodation providers, travel agents or third party tour and transport operator fees. We are not responsible for any unfavorable exchange rate fluctuations that may lead to a smaller refund in your local currency.</p>
                <p>5.8 Cancellation by the Company.</p>
                <p>5.8.1 For any Reason. We may cancel a Booking for any reason up to 30 days before the Departure Date. Except as provided below, if we cancel your Booking, you can transfer the Down Payment and Additional Payments to an alternate Departure Date or receive a full refund. In case of any cancellation for any reason, we are not responsible for any incidental expenses that you may have incurred as a result of your Booking, including but not limited to, visas, vaccinations, unfavorable exchange rate fluctuations, travel insurance excess or non-refundable flights.</p>
                <p>5.8.2 Force Majeure Event. Notwithstanding the above, the Company shall not be liable or responsible to you, nor be deemed to have defaulted under or breached this Agreement, for any failure or delay in fulfilling or performing any term of this Agreement (including without limitation any terms of a Booking), when and to the extent such failure or delay is caused by or results from acts beyond the Company’s reasonable control, including, without limitation, the following force majeure events (“Force Majeure Event(s)”): (a) acts of God; (b) flood, fire, earthquake, pandemics, epidemics, drought, bushfire, storm or other natural disaster, explosion, or public health and safety emergency characterized by an applicable government authority; (c) war, invasion, hostilities (whether war is declared or not), terrorist threats or acts, riot or other civil unrest; (d) government order, law, or actions; (e) embargoes or blockades in effect on or after the date of this Agreement; (f) national or regional emergency; (g) strikes, labor stoppages or slowdowns, or other industrial disturbances; (h) shortage of adequate power or transportation facilities; (i) other events beyond the reasonable control of the Company. In case of a Force Majeure Event, the Company shall reschedule your Booking for a future date and provide you written notice of the same. Within 30 days of notice of the Force Majeure Event, you may cancel the rescheduled Booking, and will be granted (j) a credit In the amount of the Down Payment; and (k) either a credit or a refund of the Additional Payments.</p>
                <p>5.9 Cancellation by Operator.</p>
                <p>5.9.1 If an Operator cancels the Trip with more than 30 days’ notice prior to the Departure Date, and does not reschedule the Trip, you may elect to obtain a full refund of the Down Payment and Additional Payments, or a credit in the amount of the Down Payment and Additional Payments.</p>
                <p>5.9.2 If an Operator reschedules the Trip and your Booking with more than 30 days’ notice prior to the departure date, you shall be granted a credit in the amount of the Down Payment, and at your election, a credit or full refund of the Additional Payments.</p>
                <p>5.9.3 If an Operator cancels the Trip with 30 days or less notice prior to the Departure Date,</p>
                <p>a. If the cancellation was caused due to Operator error or negligence, Company may, but is not obligated, to recover funds from the Operator and issue a refund to you accordingly;</p>
                <p>b. If the operator cancels a Trip due to a Force Majeure Event, any recovered funds will be refunded to the Traveler.</p>
                <p>5.10 Cancellation by or Removal of Host.</p>
                <p>5.10.1 Except in the event of Force Majeure, if the Host cancels your Trip and/or Booking prior to 45 days of the Departure Date, you can transfer the Down Payment and Additional Payments to an alternate Departure Date or receive a full refund.</p>
                <p>5.10.2 Except in the event of Force Majeure, if the Host cancels their participation in your Trip within 45 days of the Departure Date, the Trip will continue as planned and the Booking shall remain subject to these terms including, without limitation, the Traveler cancellation restrictions in Section 5.7 above.</p>
                <p>5.10.3 In case of any cancellation for any reason, we are not responsible for any incidental expenses that you may have incurred as a result of your Booking, including but not limited to, visas, vaccinations, unfavorable exchange rate fluctuations, travel insurance excess or non-refundable flight.</p>
                <p>5.11 Inclusions & Exclusions. Unless specified in your contract with the Operator, the price of your Booking includes:</p>
                <p>
                    <ul>
                        <li>All accommodation as listed</li>
                        <li>All transportation as listed</li>
                        <li>Activities listed</li>
                        <li>Meals as listed</li>
                        <li>Trip leader as listed</li>
                    </ul>
                    Otherwise, the price of your booking does not include any other services or products, and excludes without limitation, the following:
                    <ul>
                        <li>International or internal flights unless specified</li>
                        <li>Taxes and excess baggage charges unless specified</li>
                        <li>Meals and Beverages other than those specified</li>
                        <li>Visa and passport fees</li>
                        <li>Travel insurance</li>
                        <li>Optional activities and all personal expenses</li>
                        <li>Tour guide tips</li>
                    </ul>
                </p>
                <p>5.12 Age & Health Requirements. You must be at least 18 years of age to use the Trip Services, including, without limitation, booking any Trip. If you are Booking for an additional Traveler who is a minor or if you bring a minor to a trip, you must be at least 25 years of age, be legally authorized to act on behalf of the minor, and provide an executed guardian waiver in a form acceptable to the Company, in its sole discretion. You are solely responsible for the supervision of that minor. By Booking a trip using the Trip Services, you confirm you are at least 18 years of age. It is your sole responsibility to ensure that you are suitably fit to allow full participation in the trip and to obtain proper and detailed medical advice for the latest health requirements and recommendations for your destination. Company and our local Operators reserve the right to remove any Traveler, or deny any paid service to a Traveler, due to concerns over the health and safety of the individual or the group as a whole. Any health or safety-related removal or denial is at the discretion of Company and/or the authority on Trip.</p>
                <p>5.13 Passport and Visas. You must carry a valid passport and obtain all of the appropriate visas, permits and certificates for the countries which you will visit during your Trip. Your passport must be valid for 6 months beyond the duration of the booking. It is your sole responsibility to ensure that you are in possession of the correct visas, permits and certificates for your Trip. We are not responsible if you are refused entry to a country because you lack the correct passport, visa or other travel documentation.</p>
                <p>5.14 Travel Insurance. Travel insurance is recommended for all our travelers and should be taken out at the time of Booking. Your travel insurance should provide cover against personal accident, death, medical expenses and emergency repatriation with a recommended minimum coverage of $200,000 USD for each of the categories of cover. We also strongly recommend the travel insurance policy include coverage for cancellation, curtailment, personal liability and loss of luggage and personal effects. If an Operator requires travel insurance, you will be advised a minimum of 90 days prior to departure. A policy holder and policy number in the Traveler’s name must be provided to the Company at minimum of 72 hours prior to departure.</p>
                <p>5.15 . You agree and acknowledge that the nature of this type of travel requires considerable flexibility and you should allow for alternatives. The itinerary provided for each Booking is representative of the types of activities contemplated, but it is understood that the route, schedules, itineraries, amenities and mode of transport are subject to alteration without prior notice, and the Company is not liable for any such changes.</p>
                <p>5.16 Change of Itinerary. The Operators reserve the right to change the Trip itinerary. Please refer to our Website or other Company Programs before departure for the Operator’s most recent updates to the trip itinerary. It Is the Operator’s, and not our, sole responsibility to update the Booking itinerary.</p>
                <p>5.16.1 Before Departure. If the Operator makes a major change, we will inform you as soon as reasonably possible if there is time before the Departure Date. The definition of a major change is defined as a substantial alteration to an itinerary which can include but is not limited to; length of stay, departure date, overnight location outside of 100 mile radius of advertised location.</p>
                <p>5.16.2 When a major change is made, you may choose between accepting the new itinerary, or cancelling your Booking and obtaining a Trip Credit, without penalty, to be used on an alternative departure.</p>
                <p>5.16.3 After Departure. The Operators reserve the right to change an itinerary after Departure Due to local circumstances or events outside of their control. The additional cost of any necessary itinerary alterations will be your sole responsibility. Please note we are not responsible for any incidental expenses that may be incurred, including without limitation, expenses incurred as a result of the change of itinerary such as visas, vaccinations or non-refundable flights.</p>
                <p>5.17 Authority on Trip. If you fail to comply with a decision made by an Operator or any Trip Leader identified by Operator, or interfere with the well-being or mobility of the group, the Operator or trip manager, if identified in the Booking, may direct you to leave the trip immediately, with no right of refund. The Company may also elect not to not provide you Services in the future. You must at all times comply with the laws, customs, foreign exchange and drug regulations of all countries visited, and you also agree to travel in accordance with the Company’s responsible travel guidelines.</p>
                <p>5.18 Acceptance of Risk. You acknowledge that the nature of the Trip is adventurous and participation involves a degree of personal risk. You will be visiting places where the political, cultural and geographical attributes present dangers and physical challenges greater than those present in our daily lives. It is your sole responsibility to acquaint yourself with all relevant travel information and the nature of your itinerary. You acknowledge that your decision to travel is made in light of consideration of this information and you accept that you are aware of the personal risks attendant upon such travel.</p>
                <p>5.19 Optional Activities. Optional activities not included in the Booking price do not form part of the Booking or this Agreement. You accept that any assistance given by your group leader or local representative in arranging optional activities does not render us liable for them in any way. The contract for the provision of that activity will be between you and activity provider or Operator.</p>
                <p>5.20 Claims & Complaints. If you have a complaint about your Trip, please inform your Trip Leader identified by the Operator at the time in order that they can attempt to rectify the matter. If satisfaction is not reached through these means then any further complaint should be put in writing to the Company within 30 days of the end of the trip.</p>
                <p>5.21 Severability. In the event that any terms or conditions related to the Booking or the Trip Services, or any other terms and conditions contained in this Agreement, are unenforceable or void by operation of law or as being against public policy or for any other reason, then such term or condition shall be deemed to be severed from this Agreement or amended accordingly only to such extent necessary to allow all remaining terms and conditions to survive and continue as binding.</p>
                <p>5.22 Photos and Marketing. You consent to us using images of you taken during the trip for advertising and promotional purposes in any medium we choose. You grant us a perpetual, royalty-free, worldwide, irrevocable license to use such images for publicity and promotional purposes.</p>
                <p>5.23 Company Privacy Policy Governs Bookings. Any personal information that we collect about you may be used for any purpose associated with the operation of a Booking or otherwise associated with the Trip Services, or to send you marketing material in relation to our events and special offers. The information may be disclosed to our agents, service providers or other suppliers to enable us to provide the Trip Services. We will otherwise treat your details in accordance with our Company Privacy Policy (as defined in Section 4 of this Agreement).</p>
                <p>5.24 Pre-Trip Behavior. The Company may cancel a Booking at any time prior to the Departure date due to any Traveler actions, inactions, communications, or any other behavior that the Company deems to be a security concern, or that may impact the dynamic and safety of the Trip, as determined by the Company in its sole discretion. In the event of such cancellation, you may not be entitled to any refund or compensation. The Company may also elect to not provide you Services in the future.</p>
                <p>5.25 COVID-19 Vaccination Requirements. Some Hosts may require that all Travelers on the Trip are fully vaccinated against COVID-19. This requirement will be displayed via website copy at the time of Booking. If required, on the Departure Date, all Travelers must certify that they have been fully vaccinated as defined by the relevant national authority by Departure Date. The Host vaccination requirement is in addition to the vaccination and/or testing requirements enforced by the local authorities within the destination of travel. The Company will not facilitate the collection of proof in regards to vaccination status and is not responsible for providing or verifying this information. Host is solely responsible to implement, administer, and enforce such vaccination requirement. Traveler agrees and acknowledges that the Company disclaims all liability related to such vaccine requirements and the implementation and enforcement of such requirements.</p>
                <p>5.26 Luggage Restrictions. All travelers are allowed to bring one large piece of luggage (not exceeding 23kg (50lbs) in weight, with the dimensions (30” x 18” x 10”), one small carry-on (12”x11”x6”), and a personal item). Any additional luggage brought on a trip without Company’s pre-approval may result in additional storage fees to be paid by the Traveler.</p>
                <p>5.27 On-Trip Behavior:</p>
                <p>The Company and or authority on Trip reserves the right to remove a Traveler at any time while on trip due to any Traveler actions, inactions, communications, or any other behavior that the Company deems to be a security concern, or that may impact the dynamic and safety of the Trip, as determined by the Company in its sole discretion. In the event of such removal, the Traveler may not be entitled to any refund or compensation. The Company may also elect to not provide the Traveler Services in the future.</p>
                <p>5.28 Early Departure from a Trip: Any additional fees or costs incurred due to an early departure from the Trip, whether voluntary or at the request of Company and/or the authority on trip,  are the sole responsibility of the Traveler. This includes official removal from the Trip due to behavior, violation of community guidelines and/or health and safety concerns.</p>
                <p>6. DISCLAIMERS; LIMITATION OF LIABILITY.</p>
                <p>EXCEPT FOR THE COMPANY’S LIMITED AND EXPLICIT PROMISE UNDER SECTION 5.1.2 TO PROCESS THE TRIP PAYMENTS OF A TRAVELER PURSUANT TO THE TERMS AND CONDITIONS OF THAT TRAVELER’S BOOKING: (A) THE COMPANY MAKES NO REPRESENTATIONS, WARRANTIES OR OTHER COVENANTS TO THE TRAVELER OR ANY OTHER USER REGARDING THE COMPANY WEBSITE, ANY OTHER COMPANY PROGRAMS, ANY TRIP, ANY TRIP SERVICES, ANY OTHER COMPANY IP ASSETS, AND ANY OTHER CONTENT PROVIDED THEREIN; AND (B) THE COMPANY WEBSITE, ANY OTHER COMPANY PROGRAMS, ANY BOOKINGS, TRIP SERVICES, ANY COMPANY IP ASSETS AND ANY OTHER CONTENT PROVIDED THEREIN ARE ALL PROVIDED TO A TRAVELER AND ALL OTHER USERS ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT WARRANTIES OF ANY KIND.</p>
                <p>THE COMPANY HEREBY EXPLICITLY DISCLAIMS ANY AND ALL REPRESENTATIONS OR WARRANTIES, EITHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY: (A) WARRANTY OF MERCHANTABILITY; (B) WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE; OR (C) WARRANTY AGAINST INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY; WHETHER EXPRESS OR IMPLIED BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE, OR OTHERWISE.</p>
                <p>THE COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO ANY PRODUCTS OR SERVICES OF ANY HOST, OPERATORS OR ANY OTHER THIRD PARTY, INCLUDING WITHOUT LIMITATION ANY OF THE FOLLOWING WITH REGARD TO THE FOREGOING: (A) WARRANTY OF MERCHANTABILITY; (B) WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE; (C) WARRANTY OF TITLE; OR (D) WARRANTY AGAINST INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY; WHETHER EXPRESS OR IMPLIED BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE, OR OTHERWISE.</p>
                <p>FOR PURPOSES OF THIS SECTION 6, THESE THIRD PARTY PRODUCTS AND SERVICES INCLUDE PRODUCTS OR SERVICES OF HOSTS OR OPERATORS.</p>
                <p>WITHOUT LIMITATION THE GENERALITY OF ANY OTHER PROVISION IN THIS SECTION 6, AND FOR THE AVOIDANCE OF DOUBT, EACH TRAVELER HEREBY ACKNOWLEDGES AND AGREES THAT: (A) THE SOLE AND EXCLUSIVE RESPONSIBILITY AND LIABILITY OF THE COMPANY RELATED TO OR ARISING OUT OF THE TRAVELER’S BOOKING AND THE TRIP SERVICES SHALL BE LIMITED TO THE COMPANY’S PROCESSING OF THE TRIP PAYMENTS TO THE APPROPRIATE THIRD PARTY PURSUANT TO THE TERMS AND CONDITIONS OF THE PARTICULAR BOOKING; AND (B) ALL OTHER SERVICES RELATED TO OR ARISING OUT OF THE TRAVELER’S BOOKING OR TRIP, AND ALL OBLIGATIONS RELATED THERETO, ARE SOLELY AND EXCLUSIVELY THE RESPONSIBILITY AND LIABILITY OF THE OPERATOR, HOST OR OTHER THIRD PARTIES PROVIDING SUCH SERVICE AND ARE NOT THE RESPONSIBILITY OR LIABILITY OF THE COMPANY.</p>
                <p>IN NO EVENT SHALL THE COMPANY BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INJURY, PERSONAL INJURY, DEATH, PROPERTY DAMAGE, OR OTHER CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES WHETHER ARISING OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE AND WHETHER OR NOT THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.</p>
                <p>IN NO EVENT SHALL THE COMPANY’S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT (INCLUDING WITHOUT LIMITATION ARISING OR RELATED TO A TRAVELER’S BOOKING OR TRIP), WHETHER ARISING OUT OF OR RELATED TO BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, EXCEED THE TOTAL OF THE AMOUNTS PAID TO THE COMPANY FOR THE TRIP SERVICES SOLD HEREUNDER.</p>
                <p>7. INDEMNIFICATION. You hereby agree to defend, indemnify and hold harmless the Company, its affiliates, and their officers, directors, employees, agents, licensors, content providers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys’ fees) resulting from your violation of this Agreement, including without limitation: (a) any unauthorized use of the Company Website, any Company Programs, any Company IP Assets or any content thereof; or (b) any actions of a Traveler during a Trip.</p>
                <p>8. LINKS TO THIRD-PARTY WEBSITES. The Company Website, other Company Programs or our Trip Services may contain links to third-party websites. These links are provided for your convenience only. The Company has no control over third-party websites and we are not responsible for the content of such websites or the privacy practices of those third-party websites. If you decide to access a third-party website linked from the Company Website, other Company Programs or our Trip Services, you do so entirely at your own risk and your use of those sites is subject to those websites’ terms and conditions and privacy policies.</p>
                <p>9. GOVERNING LAW; JURISDICTION, DISPUTES. READ THIS SECTION CAREFULLY. IT AFFECTS YOUR RIGHTS AND IMPACTS HOW CLAIMS YOU AND COMPANY MAY HAVE AGAINST EACH OTHER ARE DETERMINED.</p>
                <p>9.1 Governing Law. We control and operate the Company Website, our Company Programs and our Trip Services from the State of Oregon, United States of America. While we invite users from all parts of the world to visit the Company Website and other Company Programs and to use our Trip Services, each user hereby acknowledges and agrees that the Company Website, other Company Programs and our Trip Services, and all activities available on and through the foregoing, are governed by the laws of the United States of America and the laws of the State of Oregon. Accordingly, You hereby expressly agree that the laws of the State of Oregon, excluding its conflict of laws rules shall govern this Agreement, including without limitation our Company Privacy Policy and your use of the Company Website, any other Company Programs and our Trip Services.</p>
                <p>9.2 Jurisdiction. You hereby expressly agree that exclusive jurisdiction for any claim or dispute with us (or any of our affiliates) related to or arising out of this Agreement shall be the federal and/or state courts in the State of Oregon, USA, and you further agree and expressly consent to the exercise of personal jurisdiction the federal and state courts in the State of Oregon, in connection with any such dispute and including any claim involving us or our affiliates, subsidiaries, employees, contractors, officers, directors, or agents.</p>
                <p>9.3 Prohibition of Class or Representative Actions. You and the Company agree that each of us may bring claims against the other only on an individual basis and not as a plaintiff or class member in any purported class or representative action.</p>
                <p>10. CHANGES TO THIS AGREEMENT.</p>
                <p>BY USING THE COMPANY WEBSITE, ANY COMPANY PROGRAM OR ANY TRIP SERVICES (INCLUDING WITHOUT LIMITATION BOOKING ANY TRIP THEREIN), YOU HEREBY AGREE AS FOLLOWS:</p>
                <p>10.1 The company reserves the right, at our discretion and at any time, to make changes to any of the provisions of this agreement, including without limitation changes to any of the terms and conditions governing a booking of a trip or to the company privacy policy (hereinafter “Updates to this Agreement”).</p>
                <p>10.2 The Company may take any reasonable efforts to post any such Updates to this Agreement, including without limitation posting such Updates to this Agreement on the Company’s main Website.</p>
                <p>10.3 Continued use of any Company Website, any other Company Program, or any of the Company’s Trip Services by any user (including but not limited to any traveler) shall automatically constitute such user’s (including without limitation such Traveler’s) immediate acceptance of and consent to all of the updates to this agreement and you will thereafter be bound by such Updates to this Agreement.</p>
                <p>10.4 If you do not agree to such Updates to this Agreement, then you should immediately discontinue using the Company Website, all other Company Programs, and all of the Company’s Trip Services.</p>
                <p>11. MISCELLANEOUS PROVISIONS.</p>
                <p>11.1 Entire Agreement; Waiver; Severability. This Agreement constitute the entire agreement between Company and each user (including without limitation each Traveler) with respect to the subject matter hereof, and supersedes and replaces any prior agreements we might have had between us regarding such subject matter. The Company ’s failure to enforce any right or provision of this Agreement will not be considered a waiver of those rights. If any provision of this Agreement is held to be invalid or unenforceable by a court, the remaining provisions of this Agreement will remain in effect.</p>
                <p>11.2 Remedies. User acknowledges that monetary damages may not be a sufficient remedy for unauthorized use of the Company Website, any other Company Program, any Trip Services or any other Company IP Assets and therefore each user hereby agrees that the Company shall be entitled, without waiving any other rights or remedies, to such injunctive or equitable relief as may be deemed proper by a court or arbitration panel of competent jurisdiction without necessity of posting a bond and without having to plead and prove lack of an adequate remedy at law.</p>
                <p>11.3 Attorney Fees. If any suit or action is filed by any party to enforce this Agreement or otherwise with respect to the subject matter of this Agreement, the prevailing party shall be entitled to recover reasonable attorney fees incurred in preparation or in prosecution or defense of such suit or action as fixed by the trial court and, if any appeal is taken from the decision of the trial court, reasonable attorney fees as fixed by the appellate court.</p>
                <p>11.4 Binding Effect; No Assignment by Client; Permissible Assignment by Company. This Agreement shall be binding upon and inure to the benefit of each party’s respective successors and lawful assigns; provided, however, that user (including without limitation the Traveler) may not assign this Agreement, in whole or in part, without the prior written consent of the Company (which it may or may not grant in its discretion). Any purported assignment in violation of this Section 11.4 shall be void. The Company shall have the right to assign this Agreement, or any part of it, in its sole discretion to any party, and all covenants and agreements hereunder shall inure to the benefit of and be enforceable by such successors and assigns.</p>
                <p>12. CONTACT INFORMATION.</p>
                <p>If you have questions or comments about this Agreement or the Company Websites, Company Programs or Services, please write, phone or email us via the contact information below:</p>
                                
                <h4>CONTACT INFORMATION.</h4>
                <p>If you have questions or comments about this Agreement or the Company Websites, Company Programs or Services, please write, phone or email us via the contact information below:</p>
                <p>Go-Peaks, Inc.<br>
                4148 Molino, Irvine, CA 92618<br>
                +1 (415) 999-8044<br>
                Email us: <a href="mailto:o@getadventures.co">o@getadventures.co</a></p>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile, imageSrc, SplideSlideCounter } from '@/helpers';

    import BaseButton from '../components/common/BaseButton.vue';
    import Review from '../components/common/Review.vue'

    import moment from '@/plugins/moment';
    import trips from '../store/modules/trips';
    moment.locale('ru');

    export default {
        name: 'BookingTerms',
        metaInfo() {
            return {
                title: this.lang === 'ru' ? 'Правила использования' : 'Booking Terms'
            }
        },
        components: {
            BaseButton,
            Review,
        },
        data: () => ({
            timerDate: '',
            timer: null,
            isDesktop: false,
            imageSrc,
            langUrl: '/about'
        }),
        async mounted() {
            this.$root.$emit('preloaderShow');
            await store.dispatch('reviews/fetch', { limit: 3, page: 1 });
            this.$root.$emit('preloaderHide');
        },
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            ...mapState('reviews', {
                reviews: state => state.entities
            }),
        },
        methods: {
            async authorization() {
                this.$root.$emit('popupLoginShow');
            },
            async goTo(name) {
                await this.$router.push({ name });
            },
        },
    };
</script>

<style lang="scss">
.terms {
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    // display: flex;
    // flex-direction: column;
    &__main-image {
        position: relative;
        width: 100%;
        height: 440px;
        background: url(../assets/about/About_1.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        @media all and (max-width: 768px) {
            height: 356px;
            background-position: center;
        }
        &__opacity {
            position: absolute;
            width: 100%;
            height: 100%;
            // background: #543919;
            background: #000;
            opacity: 0.5;
        }
        &__container {
        position: absolute;
        width: 100%;
        height: 440px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        margin-bottom: 40px;
            @media all and (max-width: 768px) {
                height: 356px;
            }
            &__text {
                font-weight: bold;
                font-size: 56px;
                line-height: 150%;
                letter-spacing: -0.02em;
                text-align: center;
                @media all and (max-width: 768px) {
                    font-weight: 800;
                    font-size: 24px;
                    line-height: 150%;
                    letter-spacing: -0.02em;
                }
            }
            &__comment {
                font-size: 24px;
                margin-top: 8px;
                line-height: 150%;
                letter-spacing: -0.02em;
                @media all and (max-width: 768px) {
                    font-size: 16px;
                    text-align: center;
                }
            }
        }
    }
    &__text {
        max-width: 1400px;
        margin: 0 auto;
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        &__container {
            display: flex;
        }
        > h2 {
            font-size: 30px;
            margin-bottom: 40px;
        }
        > h3 {
            font-size: 20px;
            font-weight: bold;
            align-self: center;
            &:last-of-type {
                margin-bottom: 40px;
            }
        }
        > h4 {
            font-size: 16px;
        }
        > h5 {
            font-size: 14px;
            font-style: italic;
        }
        > h6 {
            font-size: 14px;
            text-decoration: underline;
        }
        > p {
            font-size: 14px;
            margin-bottom: 20px;
            > span {
                font-weight: bold;
            }
        }
        > table {
            max-width: fit-content;
            align-self: center;
            border: 1px #000 solid;
            margin-bottom: 20px;
            td {
                padding: 0 10px;
                border: 1px #000 solid;
            }
        }
    }
}

</style>